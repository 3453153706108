<template>
  <div class="content-page">
    <div class="content-telephone">
      <!-- hideTitle -->
      <InputTelephone
        title="Change Phone Number"
        description="Please enter your phone number"
        :form="form"
        @ValidateTelephone="ValidateTelephone"
        :isValidate="$v.form.Telephone.$error"
        :v="$v.form.Telephone"
      />
    </div>
    <ModalError ref="modalError" :text="successMessage" />
  </div>
</template>

<script>
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb.vue";
import InputTelephone from "@/components/input/InputTelephone";
import { required, minLength } from "vuelidate/lib/validators";
import ModalError from "@/components/alert-modal/ModalError";
export default {
  components: {
    Breadcrumb,
    ModalError,
    InputTelephone
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "แก้ไขเบอร์โทรศัพท์",
          to: "/manage/profile"
        }
      ],
      form: {
        Telephone: "",
        x: "+66",
        code: "TH"
      },
      successMessage: ""
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async ValidateTelephone() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      await this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/user/verifytelephone`,
          this.form
        )
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            // this.$store.commit("setEditPhoneNumber", false);
            this.$router.replace({
              path: "confirmotpedit",
              query: { tel: this.form.Telephone }
            });
          } else {
            this.successMessage = data.message;
            this.$refs.modalError.show();
            this.isLoading = false;
          }
        });
    }
  },
  validations() {
    return {
      form: {
        Telephone: { required, minLength: minLength(10) }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.content-telephone {
  background-color: #fff;
  border-radius: 5px;
  // height: 100vh
}
.tel-custom {
  font-size: var(--text-xl);
  font-weight: 600;
}
.des-tel {
  font-weight: 600;
}
.ft-gray {
  color: gray;
}
.remark {
  color: red;
}
</style>
